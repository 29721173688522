import { useTitle } from '@vueuse/core'

export const useAuthProtection = () => {
    const user = useStrapiUser()

    if(user && user.value) return true

    refreshNuxtData()
    const route = useRoute()
    navigateTo('/login?redirect=' + route.path)
    return false
}
export const useAuthProtectionAdminOnly = () => {
    if( useAuthProtection() ){
        if( useIsAdmin() )
            return true
        else
            navigateTo('/')
    }
    return false
}

export const useAuthRedirect = () => {
    const user = useStrapiUser()

    if(user && user.value) {   
        const route = useRoute()
        const router = useRouter()
        refreshNuxtData()
        navigateTo(route.query?.redirect ? route.query?.redirect : '/')
    }

    return false
}

export const useGoBackToProduct = () => {
    return (ids) => {
        return useGoBack()
    }
}
export const useGoBack = () => {
    const router = useRouter()

    let history = useState('app-history', () => [])

    if(history.value.length){
        window.popStateDetected = true
        navigateTo(history.value[history.value.length-1], { replace: true })
    }
    else if(router.options.history.state.back !== null)
        router.back()
    else
    navigateTo('/')
}

const title = useTitle()
export const usePageTitle = (newTitle) => {
    if(newTitle === undefined) return title.value = 'Echtregional'
    let parts = [
        ...(Array.isArray(newTitle) ? newTitle : [newTitle]),
        'Echtregional',
    ]
    title.value = parts.filter(p => p !== undefined && p !== '' && p !== ' ').join(' - ')
}


export const useRoutingProperties = () => {
    const route = useRoute()

    let result = {}
    for(let i = 0; i < route.params?.slug?.length; i++) {
        if(!Number.isInteger(+route.params.slug[i]) && Number.isInteger(+route.params.slug?.[i+1]) ){
            result[route.params?.slug[i]] = +route.params?.slug?.[i+1]
            i++
        }
        else if( route.params.slug?.[i+1]?.startsWith('before') || route.params.slug?.[i+1]?.startsWith('between') || route.params.slug?.[i+1]?.startsWith('after')) {
            result[route.params?.slug[i]] = route.params?.slug?.[i+1]
            i++
        }
        else if( route.params.slug[i] == 'status' || route.params.slug[i] == 'show' || (route.params.slug[i] == 'business' && route.params.slug?.[i+1] == 'current') ) {
            result[route.params?.slug[i]] = route.params?.slug?.[i+1]
            i++
        }
        else if( i + 1 == route.params?.slug?.length ){
            result[route.params?.slug[i]] = true
        }
    }
    return result
}
